import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap from 'gsap' // GreenSock library
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

THREE.ColorManagement.enabled = false

/**
 * Textures
 */
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 })
    },
    // Progress
    () =>
    {

    }
)
const textureLoader = new THREE.TextureLoader(loadingManager)
const skyMatcapTexture = textureLoader.load('/textures/matcaps/spongebob.jpg')
const frontCardTexture = textureLoader.load('/textures/steven-universe.png')
const particleTexture = textureLoader.load('/textures/particles/heart.png')

const audioLoader = new THREE.AudioLoader(loadingManager)

/**
 * Debug
 */
// const gui = new dat.GUI()

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Card
 */
const cardPivot = new THREE.Group()
const card = new THREE.Group()

const frontCard = new THREE.Group()
const backCard = new THREE.Group()

// Constants
const cardPositionX = -1.4
const cardPositionY = -0.4
const cardPositionZ = 0

const cardRotationX = -0.3
const cardRotationY = -0.3
const cardRotationZ = -0.07

// Front Card
const frontPlaneGeometry = new THREE.PlaneGeometry(4, 5.5)

const frontPlaneFrontMaterial = new THREE.MeshBasicMaterial({
    map: frontCardTexture,
    side: THREE.FrontSide
})
const frontPlaneBackMaterial = new THREE.MeshBasicMaterial({
    color: 0x00bbff,
    side: THREE.BackSide
})

const frontPlaneFront = new THREE.Mesh(frontPlaneGeometry, frontPlaneFrontMaterial)
const frontPlaneBack = new THREE.Mesh(frontPlaneGeometry, frontPlaneBackMaterial)

// Transform front plane
frontPlaneFront.position.x = 2
frontPlaneBack.position.x = 2

frontCard.add(frontPlaneFront)
frontCard.add(frontPlaneBack)

// Back Card
const backPlaneGeometry = new THREE.PlaneGeometry(4, 5.5)

const backPlaneMaterial = new THREE.MeshStandardMaterial({
    color: 0x00bbff,
    side: THREE.DoubleSide
})

const backPlane = new THREE.Mesh(backPlaneGeometry, backPlaneMaterial)

// Transform back plane
backPlane.position.x = 2

backCard.add(backPlane)

// Transform front card
frontCard.rotation.y = -0.05

card.add(frontCard)
card.add(backCard)

// Transform card

card.rotation.y = cardRotationY
card.rotation.x = cardRotationX
card.rotation.z = cardRotationZ

card.position.x = cardPositionX
card.position.y = cardPositionY

cardPivot.add(card)

scene.add(cardPivot)

// // Card Debug
// const cardPivotDebug = gui.addFolder('Card Pivot')

// cardPivotDebug.add(cardPivot.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.01)

// const cardFolder = gui.addFolder('Card')

// const cardPositionDebug = cardFolder.addFolder('Position')
// const cardRotationDebug = cardFolder.addFolder('Rotation')

// cardPositionDebug.add(card.position, 'x').min(-10).max(10).step(0.1)
// cardPositionDebug.add(card.position, 'y').min(-10).max(10).step(0.1)
// cardPositionDebug.add(card.position, 'z').min(-10).max(10).step(0.1)

// cardRotationDebug.add(card.rotation, 'x').min(-Math.PI).max(Math.PI).step(0.01)
// cardRotationDebug.add(card.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.01)
// cardRotationDebug.add(card.rotation, 'z').min(-Math.PI).max(Math.PI).step(0.01)

// const frontCardFolder = cardFolder.addFolder('Front Card')

// frontCardFolder.add(frontCard.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.01)

/**
 * Fonts
 */
const fontLoader = new FontLoader()

// Front Card Text
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        const textMaterial = new THREE.MeshBasicMaterial({ color: 'black' })

        const headerFontSize = 0.4
        const headerFontHeight = 0.01
        const headerFontSpacing = 0.7

        // Front Card Text

        const textGeometriesHeaderText = ['Happy 6 Month', 'Anniversary', 'Sweetie! <3']
        const textGeometries = []

        for(let i = 0; i < textGeometriesHeaderText.length; i++)
        {
            textGeometries.push(
                new TextGeometry(
                    textGeometriesHeaderText[i],
                    {
                        font: font,
                        size: headerFontSize,
                        height: headerFontHeight,
                        curveSegments: 6,
                        bevelEnabled: false
                    }
                )
            )
        }

        textGeometries.push(
            new TextGeometry(
                '(click for more!)',
                {
                    font: font,
                    size: headerFontSize / 2,
                    height: headerFontHeight,
                    curveSegments: 6,
                    bevelEnabled: false
                }
            )
        )

        for(let i = 0; i < textGeometries.length; i++)
        {
            textGeometries[i].computeBoundingBox()
            textGeometries[i].center()

            // Top padding
            textGeometries[i].translate(
                2, 1, 0.01
            )

            // Line spacing
            textGeometries[i].translate(
                0, -1 * headerFontSpacing * i, 0
            )

            const text = new THREE.Mesh(textGeometries[i], textMaterial)
            frontCard.add(text)
        }
    }
)

// Front Card Text
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        const textMaterial = new THREE.MeshBasicMaterial({ color: 'black' })

        const headerFontSize = 0.1
        const headerFontHeight = 0.01
        const headerFontSpacing = 0.2

        // Front Card Text

        const textGeometriesHeaderText = [
            'To the sweetiest sweetie that ever sweetied,',
            '',
            'I don\'t usually do this kind of thing but here goes. I thought',
            'about for a while what I could give you for this anniversary', 
            'and this is what I had in mind (aside from the other gifts ofc', 
            'c:). I\'ll start here. Sweetie, I love you. Like, I really really really',
            'really really really really really really love you. Being with you',
            'has been the best thing to EVER happen in my life. I',
            'genuinely mean that. I swear on that <3.',
            '',
            'The way you are so thoughtful to me, the way you always',
            'are within reach, even miles apart. The way you always ask',
            'how my sleep is. The way you ask me how my classes are.',
            'And the way you listen to me all the time. The way you',
            'motivate me to finish work, staying up with me some',
            'nights. The way you make me smile with your cute dad jokes.',
            'The way you let me take care of you and trust me to ',
            'schedule your appointments. The way you can guess what I',
            'am about to say and answer my questions before I even',
            'get a chance to ask it. The way you make me excited for',
            'games like Wizard101 or SCP that I would\'ve never played',
            'otherwise and how you keep me entertained throughout.',
            'The way you take on learning cooking just to cook for',
            'me. The way you are always by my side, despite all my',
            'shortcomings. The way you are willing to break out of',
            'your comfort zone for my sake, when it comes to sharing',
        ]
        const textGeometries = []

        for(let i = 0; i < textGeometriesHeaderText.length; i++)
        {
            if (i == 0)
            {
                textGeometries.push(
                    new TextGeometry(
                        textGeometriesHeaderText[i],
                        {
                            font: font,
                            size: headerFontSize * 1.35,
                            height: headerFontHeight,
                            curveSegments: 6,
                            bevelEnabled: false
                        }
                    )
                )
            }
            else
            {
                textGeometries.push(
                    new TextGeometry(
                        textGeometriesHeaderText[i],
                        {
                            font: font,
                            size: headerFontSize,
                            height: headerFontHeight,
                            curveSegments: 6,
                            bevelEnabled: false
                        }
                    )
                )
            }
        }

        for(let i = 0; i < textGeometries.length; i++)
        {
            textGeometries[i].computeBoundingBox()

            // Top padding
            textGeometries[i].translate(
                -1.9, 2.4, 0.01
            )

            textGeometries[i].rotateY(-Math.PI)

            // Line spacing
            textGeometries[i].translate(
                0, -1 * headerFontSpacing * i, 0
            )

            const text = new THREE.Mesh(textGeometries[i], textMaterial)
            frontPlaneBack.add(text)
        }

        backCardText = textGeometries
    }
)

// Back Card Text
let backCardText = []

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        const textMaterial = new THREE.MeshBasicMaterial({ color: 'black' })

        const headerFontSize = 0.1
        const headerFontHeight = 0.01
        const headerFontSpacing = 0.2

        // Front Card Text

        const textGeometriesHeaderText = [
            'parts of your life. The way that you always keep me in the', 
            'loop and stream Grey\'s Anatomy for me, or call me when',
            'talking to roommates to make me not feel left out, or the',
            'videos you share with me that you share to your friends.',

            'You\'ve shown me these things and more in the last 6 months',
            'that I love about you, and being with you has been truly the',
            'greatest blessing. You have shown me nothing but care and',
            'it makes me want to do anything and everything for you.',
            'I love you for who you are, for what you\'ve done for me,',
            'and for how you make me into wanting to be the best',
            'person I can be just for you. Thank you for being in my life.',
            'You make my mornings or afternoons worth waking up to.',
            'I think about you every second of my life since dating you',
            '(and even a bit before too.. c:) You make me happy when',
            'you say you are proud of me. You make me want to come',
            'back from class asap to spend even more time with you.',
            'I will be with you by your side and go thru all the steps with',
            'you too. I will give you everything in the world because you',
            'and only you out of everyone in my entire life deserves it',
            'the most. <3 I will do my bestest to be the caring and',
            'loving boyfriend you deserve. No matter what. I have a lot to',
            'work on, and I have a lot to prove overtime, but with you by',
            'my side, I will be happy. Always and forever. <3',
            '',

            'I love you,',
            'Your berry'
        ]
        const textGeometries = []

        for(let i = 0; i < textGeometriesHeaderText.length; i++)
        {
            textGeometries.push(
                new TextGeometry(
                    textGeometriesHeaderText[i],
                    {
                        font: font,
                        size: headerFontSize,
                        height: headerFontHeight,
                        curveSegments: 6,
                        bevelEnabled: false
                    }
                )
            )
        }

        for(let i = 0; i < textGeometries.length; i++)
        {
            textGeometries[i].computeBoundingBox()

            // Top padding
            textGeometries[i].translate(
                0.21, 2.4, 0.0001
            )

            // Line spacing
            textGeometries[i].translate(
                0, -1 * headerFontSpacing * i, 0
            )

            const text = new THREE.Mesh(textGeometries[i], textMaterial)
            backCard.add(text)
        }

        backCardText = textGeometries
    }
)

/**
 * Sky
 */
const skyGeometry = new THREE.PlaneGeometry(24, 12);
const skyMaterial = new THREE.MeshStandardMaterial({ 
    map: skyMatcapTexture,
    side: THREE.FrontSide,
})

const sky = new THREE.Mesh(skyGeometry, skyMaterial)
sky.position.z = -1
scene.add(sky)

/**
 * Lights
 */
// Ambient Light
const ambientLight = new THREE.AmbientLight('#b9d5ff', 0.80)
scene.add(ambientLight)

// Directional light
const moonLight = new THREE.DirectionalLight('#b9d5ff', 0.12)
moonLight.position.set(4, 5, - 2)
scene.add(moonLight)

// // Light Debug
// const lightsDebug = gui.addFolder('Lights')

// const ambientLightDebug = lightsDebug.addFolder('Ambient')
// const directionalLightDebug = lightsDebug.addFolder('Directional')

// ambientLightDebug.add(ambientLight, 'intensity').min(0).max(1).step(0.001)

// directionalLightDebug.add(moonLight, 'intensity').min(0).max(1).step(0.001)
// directionalLightDebug.add(moonLight.position, 'x').min(- 5).max(5).step(0.001)
// directionalLightDebug.add(moonLight.position, 'y').min(- 5).max(5).step(0.001)
// directionalLightDebug.add(moonLight.position, 'z').min(- 5).max(5).step(0.001)

/**
 * Particles
 */
// Geometry
const particlesGeometry = new THREE.BufferGeometry()
const count = 50

const positions = new Float32Array(count * 3)
const colors = new Float32Array(count * 3)

const particlesSpacing = 15

for(let i = 0; i < count; i++)
{
    const i3 = i * 3
    let positionRandomX = (Math.random() - 0.5) * particlesSpacing
    let positionRandomY = (Math.random() - 0.5) * particlesSpacing
    let positionRandomZ = (Math.random() - 0.6) * 20

    if (positionRandomX > -3 && positionRandomX < 3
        && positionRandomY > -3 && positionRandomY < 3)
    {
        while(positionRandomX > -2 && positionRandomX < 2
            && positionRandomY > -3 && positionRandomY < 3)
        {
            positionRandomX = (Math.random() - 0.5) * particlesSpacing
            positionRandomY = (Math.random() - 0.5) * particlesSpacing
        }
    }

    positions[i3] = positionRandomX
    positions[i3 + 1] = positionRandomY
    positions[i3 + 2] = positionRandomZ
    colors[i] = Math.random()
}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

// Material
const particlesMaterial = new THREE.PointsMaterial({
    size: 4,
    sizeAttenuation: true,
    color: 'pink',
    map: particleTexture,
    transparent: true,
    alphaMap: particleTexture,
    // alphaTest: 0.001,
    // depthTest: false,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: false
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.5, 0.8, 0.9, uAlpha);
        }
    `
})

const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Animations
 */
// Click for Front Card

let frontCardOpened = false
let soundStarted = false

window.addEventListener('mousedown', () =>
{
    if (!frontCardOpened) // Closed
    {
        gsap.to(frontCard.rotation, { duration: 3, y: -Math.PI * 0.75 })
        gsap.to(card.position, { duration: 3, x: 0.25 })
        frontCardOpened = !frontCardOpened
    }
    else // Opened
    {
        gsap.to(frontCard.rotation, { duration: 3, y: -0.05 })
        gsap.to(card.position, { duration: 3, x: -2 })
        frontCardOpened = !frontCardOpened
    }

    if (!soundStarted)
    {
        try
        {
            sound.play()
            soundStarted = true
        }
        catch (error)
        {
            console.error(error)
        }
    }
})

/**
 * Helpers
 */
const len = (x, z) =>
{
    return Math.sqrt(Math.pow(x, 2) + Math.pow(z, 2))
}

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 1000)
camera.position.z = 12
scene.add(camera)


/**
 * Audio
 */
const audioListener = new THREE.AudioListener()
camera.add(audioListener)

const sound = new THREE.Audio(audioListener)

audioLoader.load( '/sounds/sweetie.ogg', (buffer) =>
{
    sound.setBuffer(buffer)
    sound.setLoop(true)
    sound.setVolume(0.5)
})


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update hearts
    for(let i = 0; i < count; i++)
    {
        const i3 = i * 3

        const xz = [particlesGeometry.attributes.position.array[i3], particlesGeometry.attributes.position.array[i3+2]]
        particlesGeometry.attributes.position.array[i3 + 1] = Math.sin(elapsedTime - len(xz[0], xz[1]) * 12) * 2
    }

    particlesGeometry.attributes.position.needsUpdate = true

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()